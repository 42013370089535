/* Space Grotesk */
@font-face {
  font-family: "Space Grotesk";
  src: url("./assets/fonts/SpaceGrotesk-VariableFont_wght.ttf");
}

/* Inter */
@font-face {
  font-family: "Inter";
  src: url("./assets/fonts/Inter-VariableFont.ttf");
}

/* Roboto Mono */
@font-face {
  font-family: "Roboto Mono";
  src: url("./assets/fonts/RobotoMono-VariableFont_wght.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
